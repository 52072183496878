import { labels } from 'src/labels/main_labels'
import Swal, { SweetAlertOptions } from 'sweetalert2'

export const deleteSwalConfig = (text: string, colorPrimary: string): SweetAlertOptions => {
  return {
    confirmButtonText: labels.es.delete,
    confirmButtonColor: colorPrimary,
    cancelButtonText: labels.es.cancel,
    cancelButtonColor: '#fff',

    html: `<h4>Estás a punto de eliminar uno o varios ${
      text ? text.toLowerCase() : ''
    }, ¿estás seguro?</h4><p>No podrás revertir este cambio</p>`,
    showCancelButton: true,
    showConfirmButton: true,
    width: 480
  }
}

export const logoutSwalConfig = (colorPrimary: string): SweetAlertOptions => {
  return {
    width: 440,
    confirmButtonText: labels.es.logout,
    confirmButtonColor: colorPrimary,
    cancelButtonText: labels.es.cancel,
    cancelButtonColor: '#fff',
    html: `<h4>${labels.es.logoutText}</h4>`,
    showCancelButton: true,
    showConfirmButton: true
  }
}

export const deleteBookingSameDay = (colorPrimary: string): SweetAlertOptions => {
  return {
    width: 440,
    confirmButtonText: 'Ok',
    confirmButtonColor: colorPrimary,
    // cancelButtonText: labels.es.cancel,
    // cancelButtonColor: '#fff',
    html: `<h6>Para anular tu cita debes ponerte en contacto con tu responsable o permanente del almacén.</h6>`,
    // showCancelButton: true,
    showConfirmButton: true
  }
}

export const deleteBookingsSwalConfig = (text: string, colorPrimary: string): SweetAlertOptions => {
  return {
    confirmButtonText: labels.es.accept,
    confirmButtonColor: colorPrimary,
    cancelButtonText: labels.es.cancel,
    preConfirm: (value) => {
      if (!value) {
        Swal.showValidationMessage('Indica un motivo')
      }
    },
    cancelButtonColor: '#fff',
    html: `<h4>Estás a punto de eliminar una ${text ? text.toLowerCase() : ''}, ¿estás seguro?</h4><p>No podrás revertir este cambio</p>`,
    showCancelButton: true,
    showConfirmButton: true,
    width: 600,
    input: 'textarea',
    inputPlaceholder: 'Introduce el motivo de cancelación'
  }
}

export const editBookingsSwalConfig = (text: string, colorPrimary: string): SweetAlertOptions => {
  return {
    confirmButtonText: labels.es.save,
    confirmButtonColor: colorPrimary,
    // cancelButtonText: labels.es.cancel,
    // cancelButtonColor: '#fff',
    // focusCancel:true,
    html: `<h5>Motivo de cambio de fecha</h5>`,
    allowOutsideClick: false,
    // showCancelButton: true,
    showConfirmButton: true,
    width: 600,
    input: 'textarea',
    inputPlaceholder: 'Introduce el motivo del cambio de cita'
  }
}

export const showStoresInvoices = (colorPrimary: string, storesInfo: any[]): SweetAlertOptions => {
  return {
    width: 600,
    confirmButtonText: 'Ok',
    confirmButtonColor: colorPrimary,
    // cancelButtonText: labels.es.cancel,
    // cancelButtonColor: '#fff',
    html: `${storesInfo.map((store) => `${store.storeName} Días:${store.totalDays} Importe:${store.totalPrice}`).join('<br>')}`,
    // showCancelButton: true,
    showConfirmButton: true
  }
}

export const sendMailInvoicesOk = (colorPrimary: string): SweetAlertOptions => {
  return {
    width: 440,
    confirmButtonText: 'Ok',
    confirmButtonColor: colorPrimary,
    // cancelButtonText: labels.es.cancel,
    // cancelButtonColor: '#fff',
    html: `<h6>Email de facturación enviados</h6>`,
    // showCancelButton: true,
    showConfirmButton: true
  }
}

export const fillSurvey = (colorPrimary: string): SweetAlertOptions => {
  return {
    width: 440,
    confirmButtonText: 'Ok',
    confirmButtonColor: colorPrimary,
    cancelButtonText: labels.es.cancel,
    cancelButtonColor: '#fff',
    html: `<h6>Por favor nos ayudaría mucho si pudieras rellenar una encuesta de satisfacción con el servicio de fisioterapia.</h6>`,
    showCancelButton: true,
    showConfirmButton: true
  }
}

export const signInAgain = (colorPrimary: string): SweetAlertOptions => {
  return {
    width: 440,
    confirmButtonText: 'Ok',
    confirmButtonColor: colorPrimary,
    html: `<h6>Cuenta vinculada correctamente, por favor inicia sesión a través de PingId de nuevo.</h6>`,
    showConfirmButton: true
  }
}
