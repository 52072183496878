import React, { useContext, useEffect, useState, ChangeEvent } from 'react'
import 'react-chat-elements/dist/main.css'
import Typography from '@mui/material/Typography'
import { Alert, Rating } from '@mui/material'
import { HeaderContext } from 'src/context/header/headerContext'
import { AlertContext } from 'src/context/alert/alertContext'
import { AuthContext } from 'src/context/auth/authContext'
import { Input, SaveButton } from './utilidades.styles'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import NoResultsLogo from 'src/components/NoResultsLogo'
import { ContainerFud } from 'src/styles/general-styles'

export default function Encuesta(): JSX.Element {
  const { showHeader } = useContext(HeaderContext)
  const { showSnackbar } = useContext(AlertContext)
  const { userData, user, firebase } = useContext(AuthContext)
  const [state, setState] = useState<any>({})
  const [width, setWidth] = useState(window.innerWidth)

  const qualificationLabels: { [index: string]: string } = {
    1: 'Poco cualificado',
    2: 'Normal',
    3: 'Cualificado',
    4: 'Excelente'
  }

  const attentionLabels: { [index: string]: string } = {
    1: 'Malo',
    2: 'Mejorable',
    3: 'Normal',
    4: 'Bueno',
    5: 'Excelente'
  }

  const saveSurvey = async () => {
    try {
      if (state) {
        await firebase.db.collection('users').doc(user.user.uid).update({ survey: state })
        showSnackbar('success', 'Encuesta enviada')
      }
    } catch (error: any) {
      showSnackbar('error', error.message)
    }
  }

  const handleChange = (event: any, value: any) => {
    setState({ ...state, [event.target.name]: value })
  }

  const Header = () => {
    return (
      <div className="flex-center ">
        <Typography variant="h3" component="h3">
          Encuesta
        </Typography>
        <Typography variant="subtitle2" component="h3"></Typography>
      </div>
    )
  }

  useEffect(() => {
    showHeader(Header)
  }, [])

  return (
    <>
      {userData.survey ? (
        <>
          <Alert sx={{ backgroundColor: 'transparent', justifyContent: 'center' }} severity="info">
            Muchas gracias por haber participado en la encuesta
          </Alert>
          <NoResultsLogo />
        </>
      ) : (
        <ContainerFud>
          <Typography sx={{ mb: 2 }} component="legend">
            Estás en una encuesta anónima….¡Adelante! Tu valoración es realmente importante para nosotros.
          </Typography>

          <Typography sx={{ mt: 2 }} component="legend">
            Puntue del 1 al 10 la calidad de las sesiones de fisioterapia
          </Typography>
          <Rating name="quality" value={state?.quality} onChange={(event, newValue) => handleChange(event, newValue)} max={10} />
          <Typography sx={{ mt: 2 }} component="legend">
            Cualificación profesional del fisioterapeuta
          </Typography>
          <Box
            sx={{
              width: width < 900 ? 400 : 600,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <FormControl>
              <RadioGroup row aria-labelledby="qualification" name="qualification" onChange={(e) => handleChange(e, e.target.value)}>
                <FormControlLabel value="Excelente" control={<Radio size="small" />} label="Excelente" />
                <FormControlLabel value="Cualificado" control={<Radio size="small" />} label="Cualificado" />
                <FormControlLabel value="Normal" control={<Radio size="small" />} label="Normal" />
                <FormControlLabel value="PocoCualificado" control={<Radio size="small" />} label="Poco cualificado" />
              </RadioGroup>
            </FormControl>
            {/* <Rating
        name="qualification"
        precision={1}
        max={4}
        // getLabelText={getLabelText}
        value={state?.qualification}
  onChange={(event,newValue) =>handleChange(event,newValue)}
        // onChangeActive={(event, newHover) => {
        //   setHover(newHover);
        // }}
        // emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {state?.qualification !== null && (
        <Box sx={{ ml: 2 }}>{qualificationLabels[state?.qualification] ||''}</Box>
      )} */}
          </Box>
          <Typography sx={{ mt: 2 }} component="legend">
            ¿El fisioterapeuta cumple los tiempos del servicio correctamente?
          </Typography>
          <Rating name="times" value={state?.times} onChange={(event, newValue) => handleChange(event, newValue)} max={10} />
          <Typography sx={{ mt: 2 }} component="legend">
            Puntue del 1 al 10 la eficacia del tratamiento aplicado
          </Typography>
          <Rating
            name="treatmentEficacy"
            value={state?.treatmentEficacy}
            onChange={(event, newValue) => handleChange(event, newValue)}
            max={10}
          />
          <Typography sx={{ mt: 2 }} component="legend">
            Su fisioterapeuta le informa en todo momento sobre el tratamiento a realizar
          </Typography>
          <FormControl>
            <RadioGroup row aria-labelledby="givenInformation" name="givenInformation" onChange={(e) => handleChange(e, e.target.value)}>
              <FormControlLabel value="yes" control={<Radio size="small" />} label="Si" />
              <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
              <FormControlLabel value="sometimes" control={<Radio size="small" />} label="A veces" />
            </RadioGroup>
          </FormControl>
          <Typography sx={{ mt: 2 }} component="legend">
            Atención y trato recibido en todo momento por el fisioterapeuta
          </Typography>
          <Box
            sx={{
              width: 600,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <FormControl>
              <RadioGroup row aria-labelledby="attention" name="attention" onChange={(e) => handleChange(e, e.target.value)}>
                <FormControlLabel value="Bueno" control={<Radio size="small" />} label="Bueno" />
                <FormControlLabel value="Normal" control={<Radio size="small" />} label="Normal" />
                <FormControlLabel value="Mejorable" control={<Radio size="small" />} label="Mejorable" />
                <FormControlLabel value="Malo" control={<Radio size="small" />} label="Malo" />
              </RadioGroup>
            </FormControl>
            {/* <Rating
        name="attention"
        precision={1}
        max={4}
        value={state?.attention}
  onChange={(event,newValue) =>handleChange(event,newValue)}
      />
      {state?.attention !== null && (
        <Box sx={{ ml: 2 }}>{attentionLabels[state?.attention] ||''}</Box>
      )} */}
          </Box>
          <Typography sx={{ mt: 2 }} component="legend">
            Puntue del 1 al 10 la eficacia de Metaphysio en la gestión del servicio
          </Typography>
          <Rating
            name="treatmentGestion"
            value={state?.treatmentGestion}
            onChange={(event, newValue) => handleChange(event, newValue)}
            max={10}
          />
          <Typography sx={{ mt: 2 }} component="legend">
            Limpieza del area de tratamiento
          </Typography>
          <Rating name="cleanArea" value={state?.cleanArea} onChange={(event, newValue) => handleChange(event, newValue)} max={10} />
          <Typography sx={{ mt: 2 }} component="legend">
            Confort de la sala de tratamiento
          </Typography>
          <Rating name="confortArea" value={state?.confortArea} onChange={(event, newValue) => handleChange(event, newValue)} max={10} />
          <Typography sx={{ mt: 2 }} component="legend">
            Entorno y ambiente durante el tratamiento
          </Typography>
          <Rating
            name="enviromentAreas"
            value={state?.enviromentAreas}
            onChange={(event, newValue) => handleChange(event, newValue)}
            max={10}
          />
          <Typography sx={{ mt: 2, mb: 2 }} component="legend">
            ¿Crees que podemos mejorar nuestros servicios?
          </Typography>
          <Input
            sx={{ '& .MuiInputBase-input': { borderEndStartRadius: 0, borderEndEndRadius: 0 }, width: '50%' }}
            placeholder={'¿Qué debemos mejorar?'}
            required
            multiline
            rows={3}
            id="suggestion"
            name="suggestion"
            autoComplete={'off'}
            onChange={(e) => handleChange(e, e.target.value)}
          />
          <div>
            <SaveButton color="primary" variant="contained" onClick={saveSurvey}>
              Guardar
            </SaveButton>
          </div>
        </ContainerFud>
      )}
    </>
  )
}
