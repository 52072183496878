import React, { useState, useContext, useEffect, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { FirebaseError } from '@firebase/util'
import CssBaseline from '@mui/material/CssBaseline'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import { ShowPasswordButton } from 'src/components/Buttons/ShowPasswordButton'

import { AuthContext } from 'src/context/auth/authContext'
import { Checkbox, Link, Grid, Box, FormHelperText } from '@mui/material'

import { Main, Input, WelcomeBox } from './welcome.styles'
import { ReactComponent as Logo } from 'src/assets/icons/general/Metaphysio.svg'
import { RecoverPasswordDialog, SimpleDialog } from 'src/components/Modals/Dialog'
import { recoverAccountPassword } from 'src/services/auth.services'
import { labels } from 'src/labels/main_labels'
import { validateEmail } from 'src/utils/validateForm'
import Swal from 'sweetalert2'
import { getAuth, getRedirectResult, AdditionalUserInfo, getAdditionalUserInfo, linkWithCredential } from 'firebase/auth'
import { rolesObj, sectorsObj, storesObj } from 'src/scripts/datats'
import { signInAgain } from 'src/utils/swal.utils'

export default function Welcome(): JSX.Element {
  const history = useHistory()
  const [error, setError] = useState<string | null>(null)
  const [openRecoverPass, setOpenRecoverPass] = useState<boolean>(false)
  const [openRecoverPassError, setOpenRecoverPassError] = useState<boolean>(false)
  const [persistence, setPersistence] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)
  const [loadingRecover, setLoadingRecover] = useState(false)
  const [recoverPasswordDialog, setRecoverPasswordDialog] = useState(false)
  const [values, setValues] = useState({ email: '', password: '' })
  const [showPassword, setShowPassword] = useState(false)

  const { firebase, userData, initLoading } = useContext(AuthContext)

  // REDIRECT FLUJO OIDC
  // useEffect(() => {
  //   ;(async () => {
  //     const auth = getAuth()

  //     getRedirectResult(auth)
  //       .then(async (result) => {
  //         console.log(result)
  //         if (result && result?.user) {
  //           const userData: AdditionalUserInfo | null = getAdditionalUserInfo(result)

  //           // Expresión regular para buscar específicamente 'ROLE-METAPHYSIO-ROLE_' seguido de un número
  //           const pattern = /ROLE-METAPHYSIO-ROLE_(\d+)/

  //           // Filtrar y extraer el número
  //           const roleNumber = (userData?.profile?.memberof as any).reduce((acc: any, item: any) => {
  //             const match = item.match(pattern)
  //             if (match) {
  //               acc.push(match[1]) // extrae el número encontrado después de 'ROLE-METAPHYSIO-ROLE_'
  //             }
  //             return acc
  //           }, [])

  //           if (!roleNumber) {
  //             const user = auth.currentUser
  //             user
  //               ?.delete()
  //               .then(async () => {
  //                 Swal.fire({
  //                   icon: 'error',
  //                   width: 440,
  //                   confirmButtonText: 'OK',
  //                   confirmButtonColor: '#1870c7',
  //                   html: `<h6>Acceso no permitido</h4>`,
  //                   showConfirmButton: true
  //                 })
  //                 console.log('user deleted due to not permission')
  //               })
  //               .catch(async (error: any) => {
  //                 await firebase.db
  //                   .collection('errorslogs')
  //                   .add({ error: JSON.stringify(error, Object.getOwnPropertyNames(error)), createdAt: Date.now() })
  //                 console.log(error)
  //               })

  //             return
  //           }

  //           if (userData?.isNewUser) {
  //             const userInfo = firebase.db.collection('users').doc(result.user.uid)
  //             if (userData.profile) {
  //               await firebase.db.collection('pingidlogs').add({ ...userData?.profile, success: 'new', createdAt: Date.now() })
  //             }
  //             await userInfo
  //               .set({
  //                 birthday: '01/01/1900',
  //                 company: { id: 'xnR745oszYN4mRTAJiLY', name: 'Obramat (BRICOLAJE BRICOMAN, S.L.U.)' },
  //                 companyId: 'xnR745oszYN4mRTAJiLY',
  //                 createdAt: Date.now(),
  //                 comunPatology: { id: false, name: 'No' },
  //                 comunPatologyBookings: 0,
  //                 deleted: false,
  //                 createdBy: 'oidc',
  //                 method: 'oidc',
  //                 email: result.user.email,
  //                 employeeId: userData?.profile?.uid,
  //                 fullName: userData?.profile?.name,
  //                 id: result.user.uid,
  //                 lastname: userData?.profile?.family_name,
  //                 lastname2: '',
  //                 name: userData?.profile?.given_name,
  //                 role: { ...rolesObj[String(userData?.profile?.title)], id: `role${roleNumber}` },
  //                 sector: userData?.profile?.departmentnumber ? sectorsObj[String(userData?.profile.departmentnumber)] : null,
  //                 // role: {
  //                 //   ...(roles.find((role) => removeAccents(role.name).toLowerCase() === String(userData?.profile?.title)?.toLowerCase()) || {
  //                 //     id: `role${roleNumber}`,
  //                 //     name: userData?.profile?.title
  //                 //   }),
  //                 //   id: `role${roleNumber}`
  //                 // },
  //                 // sector: userData?.profile?.departmentnumber
  //                 //   ? [
  //                 //       sectors.find(
  //                 //         (sector) => removeAccents(sector.name).toLowerCase() === String(userData?.profile?.departmentnumber).toLowerCase()
  //                 //       )
  //                 //     ]
  //                 //   : null,
  //                 store: storesObj[String(userData?.profile?.businesscategory)],
  //                 storeId: storesObj[String(userData?.profile?.businesscategory)].id,
  //                 // store: stores.find((store) =>
  //                 //   removeAccents(store.name).toLowerCase().includes(String(userData?.profile?.businesscategory).toLowerCase())
  //                 // ),
  //                 // storeId: stores.find((store) =>
  //                 //   removeAccents(store.name).toLowerCase().includes(String(userData?.profile?.businesscategory).toLowerCase())
  //                 // )?.id,
  //                 workingSince: String(userData?.profile?.privContractStartDate).split('/').reverse().join('/'),
  //                 gender: { id: '-', name: '-' }
  //               })
  //               .then(async () => console.log('done info'))
  //             if (userData?.profile?.departmentnumber === 'ACABADO') {
  //               const updateEmployeeSector = firebase.functions.httpsCallable('getSectorSexBirthday')

  //               try {
  //                 return await updateEmployeeSector(result.user.email)
  //               } catch (error) {
  //                 console.log(error)

  //                 throw new Error('Hubo un error mientras actualizabamos el sector')
  //               }
  //             }
  //           } else {
  //             console.log('usuario ya existia')
  //             const userInfo = await firebase.db.collection('users').doc(result.user.uid)

  //             if (userData?.profile) {
  //               await firebase.db.collection('pingidlogs').add({ ...userData?.profile, success: 'old', createdAt: Date.now() })
  //             }

  //             await userInfo
  //               .update({
  //                 deleted: false,
  //                 email: result.user.email,
  //                 employeeId: userData?.profile?.uid,
  //                 fullName: userData?.profile?.name,
  //                 lastname: userData?.profile?.family_name,
  //                 lastname2: '',
  //                 name: userData?.profile?.given_name,
  //                 // role: {
  //                 //   ...(roles.find((role) => removeAccents(role.name).toLowerCase() === String(userData?.profile?.title)?.toLowerCase()) || {
  //                 //     id: `role${roleNumber}`,
  //                 //     name: userData?.profile?.title
  //                 //   }),
  //                 //   id: `role${roleNumber}`
  //                 // },
  //                 // sector: userData?.profile?.departmentnumber
  //                 //   ? [
  //                 //       sectors.find(
  //                 //         (sector) => removeAccents(sector.name).toLowerCase() === String(userData?.profile?.departmentnumber).toLowerCase()
  //                 //       )
  //                 //     ]
  //                 //   : null,
  //                 role: { ...rolesObj[String(userData?.profile?.title)], id: `role${roleNumber}` },
  //                 sector: userData?.profile?.departmentnumber ? sectorsObj[String(userData?.profile.departmentnumber)] : null,
  //                 store: storesObj[String(userData?.profile?.businesscategory)],
  //                 storeId: storesObj[String(userData?.profile?.businesscategory)].id
  //               })
  //               .then(() => console.log('done info'))
  //               .catch(async (error: any) => {
  //                 await firebase.db
  //                   .collection('errorslogs')
  //                   .add({ error: JSON.stringify(error, Object.getOwnPropertyNames(error)), createdAt: Date.now() })
  //                 console.log(error)
  //               })
  //             if (userData?.profile?.departmentnumber === 'ACABADO') {
  //               const updateEmployeeSector = firebase.functions.httpsCallable('getSectorSexBirthday')

  //               try {
  //                 return await updateEmployeeSector(result.user.email)
  //               } catch (error) {
  //                 await firebase.db
  //                   .collection('errorslogs')
  //                   .add({ error: JSON.stringify(error, Object.getOwnPropertyNames(error)), createdAt: Date.now() })
  //                 console.log(error)

  //                 throw new Error('Hubo un error mientras actualizabamos el sector')
  //               }
  //             }
  //           }
  //         }
  //       })
  //       .catch(async (error) => {
  //         console.log(error)
  //       })
  //     //         .catch(async (error) => {
  //     //           console.log(error)
  //     //           await firebase.db
  //     //             .collection('errorslogs')
  //     //             .add({ error: JSON.stringify(error, Object.getOwnPropertyNames(error)), createdAt: Date.now() })
  //     //           if (error.toString().includes('account-exists-with-different-credential')) {
  //     //             const credential = OAuthProvider.credentialFromError(error)

  //     //             if (credential) {
  //     //               Swal.fire({
  //     //                 title: 'Vincular cuenta',
  //     //                 confirmButtonText: 'Vincular',
  //     //                 confirmButtonColor: '#1870c7',
  //     //                 showLoaderOnConfirm: true,
  //     //                 allowOutsideClick: () => !Swal.isLoading(),
  //     //                 html: `
  //     //   <span class="swal2-span" styles={{fontSize:'10px'}}>Introduce tu email y contraseña de Metaphysio</span>
  //     //   <input id="swal-input1" class="swal2-input" placeholder="Email" type="email">
  //     //   <input id="swal-input2" class="swal2-input" placeholder="Contraseña" type="password">
  //     // `,
  //     //                 focusConfirm: false,
  //     //                 preConfirm: () => {
  //     //                   firebase
  //     //                     .auth()
  //     //                     .signInWithEmailAndPassword(
  //     //                       (document.getElementById('swal-input1') as HTMLInputElement)!.value,
  //     //                       (document.getElementById('swal-input2') as HTMLInputElement)!.value
  //     //                     )
  //     //                     .then((result:any) => {
  //     //                       if (credential && result.user) {
  //     //                         return linkWithCredential(result.user, credential)
  //     //                           .then(() => {
  //     //                             // Sign in with the newly linked credential
  //     //                             return firebase.auth.signInWithCredential(credential)
  //     //                           })
  //     //                           .then((signInResult: any) => {
  //     //                             // Save the merged data to the new user

  //     //                             Swal.fire(signInAgain('#1870c7'))
  //     //                             firebase.auth.signOut()
  //     //                             // window.location.reload()
  //     //                           })
  //     //                       }
  //     //                     })
  //     //                     .catch(async (error:any) => {
  //     //                       await firebase.db
  //     //                         .collection('errorslogs')
  //     //                         .add({ error: JSON.stringify(error, Object.getOwnPropertyNames(error)), createdAt: Date.now() })
  //     //                       if ((error as any).code === 'auth/wrong-password') {
  //     //                         Swal.fire({
  //     //                           icon: 'error',
  //     //                           width: 440,
  //     //                           confirmButtonText: 'OK',
  //     //                           confirmButtonColor: '#1870c7',
  //     //                           html: `<h6>La contraseña es incorrecta, inténtelo de nuevo</h4>`,
  //     //                           showConfirmButton: true
  //     //                         })
  //     //                       } else if ((error as any).code === 'auth/too-many-requests') {
  //     //                         Swal.fire({
  //     //                           icon: 'error',
  //     //                           width: 440,
  //     //                           confirmButtonText: 'OK',
  //     //                           confirmButtonColor: '#1870c7',
  //     //                           html: `<h6>El usuario se ha bloqueado por seguridad, inténtelo de nuevo mas tarde</h4>`,
  //     //                           showConfirmButton: true
  //     //                         })
  //     //                       } else {
  //     //                         Swal.fire({
  //     //                           icon: 'error',
  //     //                           width: 440,
  //     //                           confirmButtonText: 'OK',
  //     //                           confirmButtonColor: '#1870c7',
  //     //                           html: `<h6>Ha habido un error, inténtelo de nuevo</h4>`,
  //     //                           showConfirmButton: true
  //     //                         })
  //     //                       }
  //     //                       // If there are errors we want to undo the data merge/deletion
  //     //                       console.log('Sign In Error', error)
  //     //                     })
  //     //                 }
  //     //               })
  //     //             }
  //     //           }
  //     //         })
  //   })()
  // }, [])

  useEffect(() => {
    return () => setLoading(false)
  }, [])

  const loginWithOIDC = async () => {
    firebase
      .loginOIDC()
      .then(() => {
        history.push('/')
        if (!document.getElementsByClassName('swal2-input')[0] && !userData) {
          window.location.reload()
        }
        // if (!userData) window.location.reload()
      })
      .catch((error: any) => console.log(error))
  }

  // const loginWithOIDCRedirect = async (e: any) => {
  //   if (typeof e.cancelable !== 'boolean' || e.cancelable) {
  //     console.log('The event can be canceled')
  //     // The event can be canceled, so we do so.
  //     e.preventDefault()
  //   } else {
  //     // The event cannot be canceled, so it is not safe
  //     // to call preventDefault() on it.
  //     console.warn(`The following event couldn't be canceled:`)
  //     console.dir(e)
  //   }

  //   await firebase
  //     .oidcRedirect()
  //     // .then(() => {
  //     //   history.push('/')
  //     //   if (!document.getElementsByClassName('swal2-input')[0] && !userData) {
  //     //     window.location.reload()
  //     //   }
  //     //   // if (!userData) window.location.reload()
  //     // })
  //     .catch((error: any) => console.log(error))
  // }

  const handleSubmit = (event: any) => {
    setError(null)
    event.preventDefault()

    // const data = new FormData(event.currentTarget)
    if (!values.email || !values.password) return
    if (!validateEmail(values.email)) {
      setError(labels.es.emailError)
      return
    }
    setLoading(true)

    firebase
      .login(values.email, values.password, persistence)
      .then(() => history.push('/'))
      .catch(async (error: unknown) => {
        setLoading(false)
        if (error instanceof FirebaseError) {
          console.log(error.message)
          if (error.message.includes('EMAIL_NOT_FOUND')) {
            setError(labels.es.ERR_INVALID_EMAIL)
          } else if (error.message.includes('wrong-password')) {
            setError(labels.es.ERR_WRONG_PASS)
          } else if (error.message.includes('attempts')) {
            setError(labels.es.ERR_PASS_WRONG_TIMES)
          } else if (error.message.includes('requirements')) {
            try {
              setError(labels.es.ERR_PASS_REQUIREMENTS)
              // await recoverAccountPassword(data.get('email') as string)
            } catch (error) {
              console.log(error)
            }
          } else {
            setError(labels.es.ERR_NO_EMAIL)
          }
        }
      })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const recoverPassword = (email: string) => {
    setLoadingRecover(true)
    recoverAccountPassword(email)
      .then(() => {
        setRecoverPasswordDialog(false)
        setOpenRecoverPass(true)
        setLoadingRecover(false)
      })
      .catch(() => {
        setOpenRecoverPassError(true)
        setLoadingRecover(false)
      })
  }

  return (
    <Main>
      <CssBaseline />
      <WelcomeBox>
        <Logo width={300} height={300} />
        {/* <Typography sx={{ my: 3, textAlign: { xs: 'center' } }} component="h1" variant="h2">
          {labels.es.loginTitle}
        </Typography> */}
        <Box id="form-login" component="div" sx={{ mt: 1, maxWidth: '100%', width: { md: 500 } }}>
          <Input
            sx={{ '& .MuiInputBase-input': { borderEndStartRadius: 0, borderEndEndRadius: 0 } }}
            placeholder={labels.es.email}
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => handleChange(e)}
          />
          {/* {!['obramat.es', 'ext.obramat.es'].includes(values.email.split('@')[1]) && ( */}
          <Input
            sx={{ '& .MuiInputBase-input': { borderTop: 0, borderStartStartRadius: 0, borderStartEndRadius: 0 } }}
            placeholder={labels.es.password}
            disabled={['obramat.es', 'ext.obramat.es'].includes(values.email.split('@')[1])}
            required
            fullWidth
            name="password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            endAdornment={<ShowPasswordButton onClick={() => setShowPassword(!showPassword)} />}
            onChange={(e) => handleChange(e)}
          />
          {/* )} */}

          {error ? (
            <FormHelperText sx={{ mt: '5px' }} error id="form-login">
              {error}
            </FormHelperText>
          ) : (
            <Box sx={{ mt: 3 }} />
          )}
          <Grid container sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            {/* <Grid item>
              <FormControlLabel
                control={<Checkbox value="remember" onChange={(e) => setPersistence(e.target.checked)} color="primary" />}
                label={labels.es.maintainSession}
              />
            </Grid> */}
            <Grid item>
              <Link sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => setRecoverPasswordDialog(true)} color="primary">
                {labels.es.forgotPass}
              </Link>
            </Grid>
          </Grid>
          <LoadingButton
            // disabled={!values.email || !values.password}
            disabled={!values.email || !values.password || ['obramat.es', 'ext.obramat.es'].includes(values.email.split('@')[1])}
            type="button"
            onClick={handleSubmit}
            loading={loading}
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
          >
            {labels.es.login}
          </LoadingButton>
          <LoadingButton
            // disabled={values.email !== 'oidctest'}
            disabled={!['obramat.es', 'ext.obramat.es'].includes(values.email.split('@')[1])}
            type="button"
            onClick={loginWithOIDC}
            loading={loading}
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
          >
            {'Iniciar sesión con PingId'}
          </LoadingButton>
          <SimpleDialog
            open={openRecoverPass}
            handleClose={() => setOpenRecoverPass(false)}
            title={labels.es.weSendEmail}
            text={labels.es.recoverYourPassword}
          />
          <SimpleDialog
            category="error"
            open={openRecoverPassError}
            handleClose={() => setOpenRecoverPassError(false)}
            title={labels.es.errorWhenSendingEmail}
            text={labels.es.errorWhenSendingEmail2}
          />
          <RecoverPasswordDialog
            open={recoverPasswordDialog}
            handleClose={() => setRecoverPasswordDialog(false)}
            handleAction={recoverPassword}
            loading={loadingRecover}
          />
        </Box>
      </WelcomeBox>
    </Main>
  )
}
